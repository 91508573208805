import { DataGrid } from '@mms/mms-ui-library';
import { useRef, useState } from 'react';
import { DataGridHandle, SortColumn } from 'react-data-grid';

import { EquipmentControls } from '@/components/Equipment/EquipmentControls';
import { EquipmentFilters } from '@/components/Equipment/EquipmentControls/types';
import { overrideGridStyles } from '@/components/GridRenderers/styles';
import { removeColumnWidthFromGridTemplate } from '@/components/GridRenderers/utils/removeColumnWidthFromGridTemplate';
import { PageHeader } from '@/components/PageHeader';
import { SearchInput } from '@/components/SearchInput';
import 'react-data-grid/lib/styles.css';
import {
	isHeadOfDepartment,
	SortDirection,
	ROWS_PER_PAGE,
	INITIAL_ORDER_BY_VALUE,
} from '@/constants/index';
import { PageTitles } from '@/constants/pageTitles';
import { EquipmentProvider } from '@/context/Equipment';
import { useToast, useAuth } from '@/context/index';
import { EquipmentForm } from '@/forms/EquipmentForm';
import { parseMomentDateToUTCString } from '@/helpers/date';
import { useDebounce } from '@/hooks/useDebounce';
import { usePageWithSearch } from '@/hooks/usePageWithSearch';
import { useEquipmentsValues } from '@/pages/hooks/useEquipmentsValues';
import { useShowModal } from '@/pages/hooks/useShowModal';
import { PageWrapper } from '@/pages/styles';
import { useGetEquipments } from '@/queries/equipment/useGetEquipments';

export function EquipmentPage() {
	const { role, position } = useAuth();
	const [rowsPerPage, setRowsPerPage] = useState(Number(ROWS_PER_PAGE));

	const [order, setOrder] = useState(SortDirection.ASC);
	const [sortColumn, setSortColumns] = useState<readonly SortColumn[]>([]);
	const [orderBy, setOrderBy] = useState(INITIAL_ORDER_BY_VALUE);
	const { page, setPage, search, setSearch } = usePageWithSearch();
	const [filters, setFilters] = useState<EquipmentFilters>({});
	const debouncedSearch = useDebounce(search, 500);
	const toast = useToast();

	const { id, handleOpen, handleClose, isOpen } = useShowModal();

	const handleEdit = (equipmentId?: string | number) => () => {
		handleOpen(equipmentId);
	};

	const gridTemplateRef = useRef<DataGridHandle>(null);

	const { data, dataUpdatedAt, isFetching, isLoading, refetch } =
		useGetEquipments(
			toast,
			page,
			Number(rowsPerPage),
			order,
			orderBy,
			debouncedSearch,
			filters?.availability === 'Available' ? true : null,
			filters?.user?.id,
			filters?.equipmentType?.name,
			filters?.approver?.id,
			filters?.equipmentLocation?.name,
			parseMomentDateToUTCString(filters?.firstDay),
			parseMomentDateToUTCString(filters?.lastDay),
			{ enabled: Boolean(position) }
		);

	const values = useEquipmentsValues(
		data!,
		dataUpdatedAt,
		page,
		rowsPerPage,
		handleEdit,
		isFetching,
		isLoading,
		role
	);

	const gridTemplate = removeColumnWidthFromGridTemplate(
		1,
		gridTemplateRef.current?.element
	);

	return (
		<>
			<PageWrapper>
				<PageHeader
					title={PageTitles.Equipment}
					divider={false}
					onAdd={!isHeadOfDepartment(role) ? handleOpen : undefined}
					searchInput={
						<SearchInput
							value={search}
							onChange={setSearch}
							placeholder="Search"
						/>
					}
					onRefresh={refetch}
				/>
				<div>
					<EquipmentControls
						{...{
							orderBy,
							filters,
							setFilters,
							debouncedSearch,
						}}
						filtersOptions={data?.filterValues}
					/>
				</div>

				<DataGrid
					rows={values.rows}
					columns={values.headCells}
					sortColumns={sortColumn}
					onSortColumnsChange={(sortColumns) => {
						setSortColumns(sortColumns);
						setOrder(
							(sortColumns[0]?.direction?.toLowerCase() as SortDirection) ||
								SortDirection.ASC
						);
						setOrderBy(sortColumns[0]?.columnKey || INITIAL_ORDER_BY_VALUE);
					}}
					overrideDataGridStyles={overrideGridStyles(gridTemplate)}
					paginationProps={{
						total: (data?.total as number) || 1,
						perPage: rowsPerPage,
						perPageOptions: [25, 50, 100],
						onPerPageChange: (perPage: number) => setRowsPerPage(perPage),
						currentPage: page,
						onCurrentPageChange: (currentPage: number) => setPage(currentPage),
					}}
					ref={gridTemplateRef}
				/>
			</PageWrapper>

			{isOpen && (
				<EquipmentProvider>
					<EquipmentForm
						refetch={refetch}
						id={id as string}
						onClose={handleClose}
					/>
				</EquipmentProvider>
			)}
		</>
	);
}
