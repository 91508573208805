import { styled } from '@mui/material';

export const HeaderCellContentWrapper = styled('div')(({ theme }) => ({
	display: 'flex',
	justifyContent: 'left',
	gap: theme.spaces.s,
}));

export const SortIconWrapper = styled('div')(() => ({
	display: 'flex',
	alignItems: 'center',
}));
