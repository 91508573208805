const API_BASE_PREFIX = '/api';
export const GET_CLIENTS_API_ENDPOINT = `${API_BASE_PREFIX}/Clients`;
export const GET_PROJECTS_API_ENDPOINT = `${API_BASE_PREFIX}/projects`;
export const GET_DEPARTMENTS_API_ENDPOINT = `${API_BASE_PREFIX}/departments`;
export const GET_USERS_API_ENDPOINT = `${API_BASE_PREFIX}/users`;
export const GET_ACCOUNT_API_ENDPOINT = `${API_BASE_PREFIX}/account`;
export const MICROSOFT_LOGIN_REDIRECT = `${API_BASE_PREFIX}/account/login`;
export const REFRESH_JWT_TOKEN = `${API_BASE_PREFIX}/account/refresh-token`;
export const LOGOUT_URL = `${API_BASE_PREFIX}/account/logout`;
export const GET_TIMESHEET_API_ENDPOINT = `${API_BASE_PREFIX}/timesheet`;
export const GET_DASHBOARD_API_ENDPOINT = `${API_BASE_PREFIX}/Dashboard`;
export const TIMESHEET_MANAGER_API_ENDPOINT = `${API_BASE_PREFIX}/timesheetManager`;
export const UPDATE_CURRENT_USER = `${API_BASE_PREFIX}/users/current`;
export const GET_KNOWLEDGE_BASE = `${API_BASE_PREFIX}/KnowledgeBase`;
export const GET_REPORTS = `${API_BASE_PREFIX}/Reports`;
export const GET_CLIENTS_LOOKUP = `${GET_CLIENTS_API_ENDPOINT}/lookup`;
export const GET_PROJECTS_LOOKUP = `${GET_PROJECTS_API_ENDPOINT}/lookup`;
export const GET_REPORTS_EXCEL = `${GET_REPORTS}/excel`;
export const GET_REPORTS_CSV = `${GET_REPORTS}/csv`;
export const GET_CERTIFICATES_API_ENDPOINT = `${API_BASE_PREFIX}/certificate`;
export const GET_NOTIFICATIONS = `${API_BASE_PREFIX}/Notifications`;
export const GET_RESERVATION_API_ENDPOINT = `${API_BASE_PREFIX}/Reservation`;
export const GET_BOOKING_API_ENDPOINT = `${API_BASE_PREFIX}/Booking`;
export const GET_COLLISIONS_API_ENDPOINT = `${API_BASE_PREFIX}/Collisions`;
export const GET_CLIENT_PROJECTS = `${GET_CLIENTS_API_ENDPOINT}/projects`;
export const GET_FILE = `${API_BASE_PREFIX}/ApplicationFile`;
export const GET_EQUIPMENT_API_ENDPOINT = `${API_BASE_PREFIX}/Equipment`;
export const GET_EQUIPMENT_HISTORY_API_ENDPOINT = `${API_BASE_PREFIX}/EquipmentHistory`;
export const GET_SIGNATURE = `${GET_USERS_API_ENDPOINT}/current/signature`;
export const GET_EQUIPMENT_FILE = `${GET_EQUIPMENT_API_ENDPOINT}/file`;
