import { SvgIconProps, createSvgIcon } from '@mui/material';

const Edit = createSvgIcon(
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
	>
		<path
			d="M13.4619 8.00265L15.903 10.4556L9.72394 16.6649L7.28425 14.2119L13.4619 8.00265ZM17.7553 7.41104L16.6666 6.31708C16.2459 5.89431 15.5628 5.89431 15.1406 6.31708L14.0978 7.36498L16.5389 9.81801L17.7553 8.59569C18.0816 8.26776 18.0816 7.73894 17.7553 7.41104ZM6.00679 17.6598C5.96237 17.8607 6.14288 18.0408 6.34283 17.9919L9.063 17.3291L6.62331 14.8761L6.00679 17.6598Z"
			fill="#F17900"
		/>
	</svg>,
	'Edit'
);

export function EditIcon({ sx, ...props }: SvgIconProps) {
	return (
		<Edit
			sx={{
				width: 24,
				height: 24,
				...sx,
			}}
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		/>
	);
}
