import { Theme, css } from '@mui/material';

import { DEFAULT_GRID_PAGINATION_HEIGHT } from './constants';

export const overrideGridStyles =
	(templateColumns?: string) => (theme: Theme) =>
		css`
			height: calc(100% - ${DEFAULT_GRID_PAGINATION_HEIGHT}px);

			${templateColumns}

			color: ${theme.palette['base-6']};
			.rdg-cell {
				border-block-end: unset;
				border-right: 0;
				border-left: 0;
				border-bottom: 1px solid ${theme.palette['base-1']};
				display: inline-block;
				text-overflow: ellipsis;
				background-color: ${theme.palette['primary-1']};
				outline: 0 solid white;
				& span {
					text-overflow: ellipsis;
					width: 100%;
				}
			}

			.rdg-row-odd .rdg-cell {
				background-color: ${theme.palette['primary-2']};
			}

			.rdg-row-even .rdg-cell {
				background-color: ${theme.palette['primary-1']};
			}

			.rdg-cell-frozen {
				grid-column: auto !important;
				right: 0;
				margin: 0;
				padding: 0;
				top: 20px;
				width: 32px;
				display: flex;
				justify-content: center;
				align-items: center;
			}

			.rdg-cell[role='columnheader'] {
				border-bottom: 1px solid ${theme.palette['primary-4']};
				display: flex;
				align-items: center;
			}

			&::-webkit-scrollbar {
				height: 4px;
			}
		`;
