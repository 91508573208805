import { Skeleton } from '@mui/material';
import { RenderCellProps } from 'react-data-grid';

import { GridRowDefaultProps } from '../../types';

import { EditIcon } from '@/assets/buttons/EditIcon';
import { isHeadOfDepartment } from '@/constants/roles';

import { EditButton } from './styles';

export const defaultEditCellRenderer = <T extends GridRowDefaultProps>({
	row: { id, handleEditClick, showSkeleton, role },
	column: { minWidth },
}: RenderCellProps<T, unknown>) => {
	if (isHeadOfDepartment(role)) {
		return null;
	}

	return showSkeleton ? (
		<Skeleton animation="wave" width={minWidth} />
	) : (
		<EditButton onClick={handleEditClick(id)} color="secondary">
			<EditIcon />
		</EditButton>
	);
};
