import { Skeleton } from '@mui/material';
import { RenderCellProps } from 'react-data-grid';

import { GridRowDefaultProps } from '../../types';

import { CellText } from './styles';

export const defaultCellRenderer = <T extends GridRowDefaultProps>({
	row,
	column: { minWidth, key },
}: RenderCellProps<T, unknown>) => {
	const info = row[key as keyof T] as string;
	const isLoading = row?.showSkeleton;

	return isLoading ? (
		<Skeleton animation="wave" width={minWidth} />
	) : (
		<CellText>{info}</CellText>
	);
};
