import axios, { AxiosError } from 'axios';
import buildQuery from 'odata-query';
import { UseQueryOptions, useQuery } from 'react-query';

import {
	EquipmentQueriesKeys,
	GET_EQUIPMENT_API_ENDPOINT,
	NotificationType,
	SOMETHING_WENT_WRONG,
	SortDirection,
} from '@/constants/index';
import { ToastContextValues } from '@/context/types';
import { capitalizeString } from '@/helpers/capitalizeString';
import { createToast } from '@/helpers/createToast';
import { normalizeSearchString } from '@/helpers/normalizeSearchString';
import { GetEquipmentsResponse } from '@/queries/types';

export const useGetEquipments = (
	toast: ToastContextValues,
	skip: number,
	top: number,
	order: SortDirection,
	orderByCondition: string,
	search: string,
	isAvailable: true | null,
	assignedToUserId: number | undefined,
	equipmentType: string | undefined,
	approverId: number | undefined,
	location: string | undefined,
	purchaseDateFrom: string | null,
	purchaseDateTo: string | null,
	options: UseQueryOptions<GetEquipmentsResponse, AxiosError> = {}
) =>
	useQuery<GetEquipmentsResponse, AxiosError>({
		queryKey: [
			EquipmentQueriesKeys.equipments,
			skip,
			top,
			order,
			orderByCondition,
			search,
			isAvailable,
			assignedToUserId,
			equipmentType,
			approverId,
			location,
			purchaseDateFrom,
			purchaseDateTo,
		],
		queryFn: async () => {
			try {
				const normalizedSearch = normalizeSearchString(search).trim();

				const dateFilterString =
					purchaseDateFrom && purchaseDateTo
						? `${purchaseDateFrom} lt cast(purchaseDate, Edm.DateTimeOffset) and ${purchaseDateTo} gt cast(purchaseDate, Edm.DateTimeOffset)`
						: undefined;

				const searchFilter = normalizedSearch && {
					or: [
						`contains(tolower(activeHolder/department), tolower('${normalizedSearch}'))`,
						`contains(tolower(activeHolder/firstName), tolower('${normalizedSearch}'))`,
						`contains(tolower(activeHolder/lastName), tolower('${normalizedSearch}'))`,
						`contains(tolower(approver/firstName), tolower('${normalizedSearch}'))`,
						`contains(tolower(approver/lastName), tolower('${normalizedSearch}'))`,
						`contains(tolower(location), tolower('${normalizedSearch}'))`,
						`contains(tolower(type), tolower('${normalizedSearch}'))`,
						`contains(tolower(name), tolower('${normalizedSearch}'))`,
						`contains(tolower(serialNumber), tolower('${normalizedSearch}'))`,
						`contains(tolower(purchasePlace), tolower('${normalizedSearch}'))`,
						`contains(cast(id, Edm.String), cast(${+normalizedSearch}, Edm.String))`,
						`contains(cast(purchasePrice, Edm.String), cast('${normalizedSearch}', Edm.String))`,
						`contains(cast(purchaseDate, Edm.String), cast('${normalizedSearch}', Edm.String))`,
						`contains(cast(guaranteeDate, Edm.String), cast('${normalizedSearch}', Edm.String))`,
					],
				};

				const query = buildQuery({
					filter: {
						and: [
							{
								activeHolder: {
									id: assignedToUserId,
								},
							},
							{
								activeHolder: {
									id: isAvailable ? null : undefined,
								},
							},
							{ type: equipmentType },
							{ location },
							{
								approver: {
									id: approverId,
								},
							},
							dateFilterString,
							searchFilter,
						],
					},
					orderBy: `${capitalizeString(orderByCondition)} ${order}`,
					top,
					skip: skip * top,
				});

				const res = await axios.get<GetEquipmentsResponse>(
					`${GET_EQUIPMENT_API_ENDPOINT}${query}`
				);

				return res.data;
			} catch (error) {
				toast.open(
					createToast(NotificationType.ERROR, null, SOMETHING_WENT_WRONG)
				);

				return Promise.reject(error);
			}
		},
		keepPreviousData: true,
		refetchOnWindowFocus: false,
		...options,
	});
