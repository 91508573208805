import { North, South } from '@mui/icons-material';
import { RenderHeaderCellProps } from 'react-data-grid';

import { GridRowDefaultProps } from '../../types';

import { SortDirection } from '@/constants/sort';

import { HeaderCellContentWrapper, SortIconWrapper } from './styles';

export const defaultHeaderCellRenderer = <T extends GridRowDefaultProps>({
	column: { name },
	sortDirection,
}: RenderHeaderCellProps<T, unknown>) => (
	<HeaderCellContentWrapper>
		<span>{name}</span>
		{sortDirection && (
			<SortIconWrapper>
				{sortDirection?.toLowerCase() === SortDirection.ASC ? (
					<North sx={{ height: 12, width: 12 }} />
				) : (
					<South sx={{ height: 12, width: 12 }} />
				)}
			</SortIconWrapper>
		)}
	</HeaderCellContentWrapper>
);
