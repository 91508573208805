import { Moment } from 'moment';
import numeral from 'numeral';
import { useMemo } from 'react';

import { EQUIPMENT_HEAD_CELLS } from '../Equipment/constants';

import { NUMERAL_PRICE_FORMAT, Roles } from '@/constants/index';
import { formatDateString } from '@/helpers/dateToISOString';
import { formatFullName } from '@/helpers/index';
import { ByPageResponse } from '@/queries/types';
import {
	EquipmentItemResponse,
	EquipmentGridRow,
	UseEquipmentValueReturnType,
} from '@/types/Equipment';

export const useEquipmentsValues = (
	data: ByPageResponse<EquipmentItemResponse>,
	dataUpdatedAt: Moment | Date | number,
	page: number,
	rowsPerPage: number,
	handleEdit: (equipmentId?: string | number) => () => void,
	isFetching: boolean,
	isLoading: boolean,
	role: Roles
): UseEquipmentValueReturnType => {
	const showSkeleton = isLoading || isFetching;

	const mockRow: EquipmentGridRow = {
		id: '',
		serialNumber: '',
		name: '',
		type: '',
		purchasePriceUsd: '',
		'activeHolder/firstName': '',
		'activeHolder/department': '',
		equipmentCharacteristics: '',
		purchasePlace: '',
		'approver/id': '',
		purchaseDate: '',
		guaranteeDate: '',
		location: '',
		comment: '',
		handleEditClick: handleEdit,
		showSkeleton,
		role,
	};

	return useMemo(
		() => ({
			headCells: EQUIPMENT_HEAD_CELLS,
			rows:
				showSkeleton || !data?.items
					? (new Array(20).fill(mockRow) as EquipmentGridRow[])
					: data?.items?.map(
							({
								id,
								name,
								equipmentType,
								purchasePriceUsd,
								approver,
								comment,
								activeHolder,
								guaranteeDate,
								location,
								serialNumber,
								characteristics,
								purchaseDate,
								purchasePlace,
							}) => ({
								id,
								serialNumber,
								name,
								type: equipmentType,
								purchasePriceUsd:
									numeral(purchasePriceUsd).format(NUMERAL_PRICE_FORMAT),
								'activeHolder/firstName': formatFullName(
									activeHolder?.lastName as string,
									activeHolder?.firstName as string
								),
								'activeHolder/department': activeHolder?.department ?? '-',
								equipmentCharacteristics: characteristics,
								purchasePlace,
								'approver/id': formatFullName(
									approver?.lastName,
									approver?.firstName
								),
								purchaseDate: formatDateString(purchaseDate),
								guaranteeDate: formatDateString(guaranteeDate),
								location,
								comment,
								handleEditClick: handleEdit,
								showSkeleton,
								role,
							})
					  ),
		}),
		[dataUpdatedAt, page, rowsPerPage]
	);
};
