import { Tooltip } from '@mms/mms-ui-library';
import { Skeleton } from '@mui/material';
import { RenderCellProps } from 'react-data-grid';

import { GridRowDefaultProps } from '../../types';

import { ClippedCellText } from './styles';

export const defaultClippedCellRenderer = <T extends GridRowDefaultProps>({
	row,
	column: { minWidth, key },
}: RenderCellProps<T, unknown>) => {
	const info = row[key as keyof T] as string;
	const isLoading = row.showSkeleton;

	return isLoading ? (
		<Skeleton animation="wave" width={minWidth} />
	) : (
		<Tooltip
			zIndex={5000}
			text={info}
			position="bottom"
			fallbackPlacements={['bottom']}
			useAnchorWidth
			useIfTextCut
		>
			<ClippedCellText>{info}</ClippedCellText>
		</Tooltip>
	);
};
