import { Column } from 'react-data-grid';

import { defaultCellRenderer } from '@/components/GridRenderers/renderers/defaultCellRenderer';
import { defaultClippedCellRenderer } from '@/components/GridRenderers/renderers/defaultClippedCellRenderer';
import { defaultEditCellRenderer } from '@/components/GridRenderers/renderers/defaultEditCellRenderer';
import { defaultHeaderCellRenderer } from '@/components/GridRenderers/renderers/defaultHeaderCellRenderer';
import { EquipmentGridRow } from '@/types/Equipment';

export const EQUIPMENT_HEAD_CELLS: readonly Column<EquipmentGridRow>[] = [
	{
		key: 'id',
		name: 'Id',
		sortable: true,
		renderHeaderCell: defaultHeaderCellRenderer,
		renderCell: defaultCellRenderer,
		minWidth: 110,
	},
	{
		key: 'serialNumber',
		name: 'Serial Number',
		minWidth: 200,
		renderHeaderCell: defaultHeaderCellRenderer,
		renderCell: defaultCellRenderer,
	},
	{
		key: 'name',
		name: 'Name',
		sortable: true,
		minWidth: 270,
		renderHeaderCell: defaultHeaderCellRenderer,
		renderCell: defaultCellRenderer,
	},
	{
		key: 'type',
		name: 'Type',
		sortable: true,
		minWidth: 180,
		renderHeaderCell: defaultHeaderCellRenderer,
		renderCell: defaultCellRenderer,
	},
	{
		key: 'purchasePriceUsd',
		name: 'Price, $',
		sortable: true,
		minWidth: 100,
		renderHeaderCell: defaultHeaderCellRenderer,
		renderCell: defaultCellRenderer,
	},
	{
		key: 'activeHolder/firstName',
		name: 'User',
		sortable: true,
		minWidth: 350,
		renderHeaderCell: defaultHeaderCellRenderer,
		renderCell: defaultCellRenderer,
	},
	{
		key: 'activeHolder/department',
		name: 'Department',
		sortable: true,
		minWidth: 150,
		renderHeaderCell: defaultHeaderCellRenderer,
		renderCell: defaultCellRenderer,
	},
	{
		key: 'equipmentCharacteristics',
		name: 'Equipment characteristics',
		minWidth: 400,
		renderCell: defaultClippedCellRenderer,
		renderHeaderCell: defaultHeaderCellRenderer,
	},
	{
		key: 'purchasePlace',
		name: 'Purchase place',
		minWidth: 340,
		renderHeaderCell: defaultHeaderCellRenderer,
		renderCell: defaultCellRenderer,
	},
	{
		key: 'approver/id',
		name: 'Approver',
		sortable: true,
		minWidth: 280,
		renderHeaderCell: defaultHeaderCellRenderer,
		renderCell: defaultCellRenderer,
	},
	{
		key: 'purchaseDate',
		name: 'Purchase date',
		sortable: true,
		minWidth: 130,
		renderHeaderCell: defaultHeaderCellRenderer,
		renderCell: defaultCellRenderer,
	},
	{
		key: 'guaranteeDate',
		name: 'Guarantee date',
		minWidth: 130,
		renderHeaderCell: defaultHeaderCellRenderer,
		renderCell: defaultCellRenderer,
	},
	{
		key: 'location',
		name: 'Location',
		sortable: true,
		minWidth: 150,
		renderHeaderCell: defaultHeaderCellRenderer,
		renderCell: defaultCellRenderer,
	},
	{
		key: 'comment',
		name: 'Comment',
		minWidth: 400,
		renderCell: defaultClippedCellRenderer,
		renderHeaderCell: defaultHeaderCellRenderer,
	},
	{
		key: '',
		name: '',
		frozen: true,
		width: 1,
		renderCell: defaultEditCellRenderer,
	},
];
